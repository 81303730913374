import React from "react";

import useBlogPosts from "../hooks/blogPosts";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/pageTitle";
import PostCard from "../components/postCard";

const BlogPage = () => {
    const posts = useBlogPosts();

    return (
        <Layout>
            <SEO title="Blog" />
            <PageTitle title="Blog" />
            {   posts.length ?
                posts.map((p) => <PostCard key={p.node.id} post={p.node} />) :
                <p>Oops, no posts yet.</p>
            }
        </Layout>
    );
};

export default BlogPage;
