import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    pageTitle: {
        margin: "0",
        marginBottom: "2rem",
    },
});

export const PageTitle = ({ title }) => {
    const classes = useStyles();
    return (
        <h3 className={classes.pageTitle}>{title}</h3>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageTitle;
