import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { createUseStyles } from "react-jss";

import { postDateFormat } from "../utils/date";
import SEO from "../components/seo";
import Layout from "../components/layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";
import { faTags, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles({
    header: {
        marginBottom: "2rem",
    },
    title: {
        margin: "0",
        marginBottom: ".5rem",
    },
    subtitle: {
        fontSize: ".9em",
        color: "var(--accent-color)",
        "& > span": {
            display: "inline-block",
            whiteSpace: "nowrap",
        },
        "& > span:not(:last-child)": {
            marginRight: "1.5rem",
        },
    },
    back: {
        marginBottom: "2rem",
    },
});

export const BlogPostInfo = ({ date, ttr, tags }) => {
    const styles = useStyles();
    const formattedDate = postDateFormat(date);
    return (
        <div className={styles.subtitle}>
            <span><FontAwesomeIcon icon={faCalendarAlt} /> {formattedDate}</span>
            <span><FontAwesomeIcon icon={faClock} /> {ttr} min read</span>
            {tags.length > 0 && <span><FontAwesomeIcon icon={faTags} /> {tags.map((t) => t.toUpperCase()).join(", ")}</span>}
        </div>
    );
};

BlogPostInfo.propTypes = {
    date: PropTypes.string.isRequired,
    ttr: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
};

export const BlogPost = ({ data }) => {
    const styles = useStyles();
    const { markdownRemark: post } = data;

    const { html, frontmatter: { title, date, tags }, timeToRead } = post;

    return (
        <Layout>
            <SEO title={title} />
            <section className={styles.back}>
                <Link to="/blog">
                    <FontAwesomeIcon icon={faAngleLeft} /> See all posts
                </Link>
            </section>
            <article>
                <header className={styles.header}>
                    <h2 className={styles.title}>{title}</h2>
                    <BlogPostInfo date={date} ttr={timeToRead} tags={tags} />
                </header>
                <section dangerouslySetInnerHTML={{ __html: html }} />
            </article>
            <footer>
                <hr />
                <p>
                    Thanks for reading. If you have questions or suggestions, you can find my contact info just below.
                </p>
            </footer>
        </Layout>
    );

};

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object.isRequired,
    }).isRequired,
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date
        tags
      }
      timeToRead
    }
  }
`;

export default BlogPost;
