import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { createUseStyles } from "react-jss";

import { BlogPostInfo } from "../templates/blogPost";

const useStyles = createUseStyles({
    card: {
        backgroundColor: "var(--card-bg)",
        boxShadow: "0px 0px 4px 0px var(--box-shadow-color)",
        padding: "1em 1.2em",
        marginBottom: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    title: {
        width: "fit-content",
        "& > h4": {
            margin: "0",
            marginBottom: ".2rem",
            fontSize: "1.1rem",
        },
    },
    summary: {
        margin: "1rem 0",
        "& > p": {
            margin: "0",
        },
    },
    info: {
        fontSize: ".9rem",
    },
});


const PostCard = ({ post: { fields: { slug }, frontmatter, timeToRead } }) => {
    const styles = useStyles();

    const { date, summary, title, tags } = frontmatter;

    return (
        <article className={styles.card}>
            <Link className={styles.title} to={slug}>
                <h4>{title}</h4>
            </Link>
            <section className={styles.summary}>
                <p>
                    {summary}
                </p>
            </section>
            <footer className={styles.info}>
                <BlogPostInfo date={date} ttr={timeToRead} tags={tags} />
            </footer>
        </article>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        fields: PropTypes.shape({ slug: PropTypes.string }),
        frontmatter: PropTypes.shape({
            date: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        timeToRead: PropTypes.number,
    }).isRequired,
};

export default PostCard;
