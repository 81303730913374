import { useStaticQuery, graphql } from "gatsby";

export default () => {
    const { allMarkdownRemark: { edges } } = useStaticQuery(
        graphql`
        query BlogPosts {
          allMarkdownRemark(filter: {fields: {slug: {ne: "/blog/example/"}}, fileAbsolutePath: {glob: "**/content/blog/**/*.md"}}, 
          sort: {order: DESC, fields: [frontmatter___date]}) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  date
                  summary
                  title
                  tags
                }
                timeToRead
              }
            }
          }
        }
        
`);
    return edges;
};
